import * as React from 'react'
import * as css from './PostSidebar.module.css'
import { Link } from 'gatsby'
import { HiArrowNarrowLeft as Left } from 'react-icons/hi'
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import { IoPaperPlane } from 'react-icons/io5'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import useSiteMetadata from 'hooks/useSiteMetadata'

const PostSidebar = ({ current }) => {
  const shareUrl = useSiteMetadata().siteUrl + '/blog/' + current.seo.slug.current

  return (
    <aside>
      <div className={`${css.root} flex items-center md:flex-col sticky-below-header`}>
        <div className={css.back}>
          <Link className="flex items-center max-w-max uppercase" to="/blog">
            <Left className="h2" />
            <span className="ml-[0.5em] md:!hidden">Back</span>
          </Link>
        </div>
        <EmailShareButton className={css.icon} url={shareUrl}>
          <IoPaperPlane className="h3" />
        </EmailShareButton>
        <FacebookShareButton className={css.icon} url={shareUrl}>
          <FaFacebookF className="h3" />
        </FacebookShareButton>
        <TwitterShareButton className={css.icon} url={shareUrl}>
          <FaTwitter className="h3" />
        </TwitterShareButton>
      </div>
    </aside>
  )
}

export default PostSidebar
