import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { BlogProvider } from 'components/blog/BlogStore'
import PostSidebar from 'components/blog/PostSidebar'
import Article from 'components/blog/Article'
import BlogCTA from 'components/common/ContactCTA'
import ReadNext from 'components/blog/ReadNext'
import { graphql } from 'gatsby'

const PostTemplate = ({ data }) => {
	const post = data.sanityBlog

	return (
		<DefaultLayout
			{...post.seo}
			className="ctnr padding"
			noindex={post.noindex}
		>
			<BlogProvider>
				<div className="grid gap-x-16 gap-y-8 md:grid-cols-[auto,1fr,250px]">
					<PostSidebar current={post} />

					<Article post={post} />

					<aside>
						<BlogCTA className="sticky-below-header" />
					</aside>
				</div>

				<ReadNext current={post} />
			</BlogProvider>
		</DefaultLayout>
	)
}

export default PostTemplate

export const query = graphql`
	query PostTemplate($id: String) {
		sanityBlog(id: { eq: $id }) {
			title
			category {
				title
			}
			_rawBody(resolveReferences: { maxDepth: 2 })
			publishDate
			formattedPublishDate: publishDate(formatString: "MMMM D, YYYY")
			featuredImage {
				asset {
					gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
				}
			}
			noindex
			seo {
				title
				description
				slug {
					current
				}
			}
		}
	}
`
