import * as React from 'react'
import * as css from './ReadNext.module.css'
import { BlogContext } from './BlogStore'
import ArticlePreview from './ArticlePreview'

const ReadNext = ({ current }) => {
  const { allBlogs } = React.useContext(BlogContext)

  const posts = allBlogs.filter(post => post.seo.slug.current !== current.seo.slug.current)

  return (
    <section className={css.root}>
      <h2 className="h2">Read This Next</h2>

      <ul className={css.list}>
        {posts.map((post, key) => (
          (key < 3) && <li key={key}>
            <ArticlePreview post={post} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ReadNext
