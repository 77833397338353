import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CTALink from 'components/common/CTALink'
import logo from 'assets/fastech-logo-green.svg'
import clsx from 'clsx'

const ContactCTA = ({ className }) => {
  const { img } = useStaticQuery(query)

  return (
    <div className={clsx(className, 'relative')}>
      <GatsbyImage
        className="!absolute inset-0"
        image={getImage(img)}
        alt=""
      />

      <div className="relative py-16 px-8 richtext">
        <img
          className="h-[20px] mb-8"
          src={logo}
          alt="FASTECH"
          draggable="false"
        />

        <h2 className="h3">Need an Energy Solutions Partner?</h2>
        <p className="text-[14px]">Let FASTECH help you bring your energy or fueling project to life.</p>

        <CTALink className="action text-[12px]" url="/contact" label="Get started" />
      </div>
    </div>
  )
}

export default ContactCTA

const query = graphql`query ContactCTA {
  img: file(relativePath: {eq: "cta/contact-cta-bg-light.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE)
    }
  }
}`
