import * as React from 'react'
import * as css from './Article.module.css'
import CategoryAndDate from './CategoryAndDate'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Article = ({ post }) => (
  <article className={css.root}>
    <h1 className="h1">{post.title}</h1>

    <CategoryAndDate {...post} className="text-[14px]" />

    {post.featuredImage && (
      <GatsbyImage
        className="mb-8 <md:fullwidth"
        image={getImage(post.featuredImage.asset)}
        alt={post.title}
      />
    )}

    <SanityBlock className={css.body} body={post._rawBody} />

    <img
      className="w-[30px] mt-8"
      src="/fastech-favicon.svg"
      alt=""
      draggable="false"
    />
  </article>
)

export default Article
